<template>
  <el-scrollbar>
    <div class="artV_body_all">
      <div style="width: 100%">
        <el-page-header title="返回" content="蔬菜百科详情" @back="goBack" />
      </div>

      <div style="width: 90%; padding: 20px">
        <div class="artV_title">{{ data.name }}</div>
        <div class="artV_info">
          <div class="artV_time">
            <div style="color: #409eff">别称：</div>
            <div>{{ data.alias }}</div>
          </div>
          <div class="artV_time">
            <div style="color: #409eff">科：</div>
            <div>{{ data.section }}</div>
          </div>

          <div class="artV_author">
            <img :src="img.imgSrc" style="width: 50px; height: 50px" alt="" />
            <div class="artV_author_text">
              <div class="artV_time">
                <div style="color: #409eff">ID：</div>
                <div>{{ data.v_id }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="artV_body">
            <div class="artV_content">
              {{ data.features }}
            </div>
            <div style="margin-left: 20px">
              <div class="artV_demo-image">
                <div v-for="fit in fits" :key="fit" class="actV_block">
                  <el-image
                    :src="img.image"
                    style="width: 280px; height: 280px; margin: 30px"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
export default {
  name: "VencV",
  setup() {
    const { proxy } = getCurrentInstance();
    const fits = ["scale-down"];
    // const imgSrc = require("../assets/zyhylogo.png");
    const img = reactive({
      image: "",
      imgSrc:""
    });
    const data = reactive({
      name: "",
      features: "",
      alias: "",
      section: "",
      picture: [],
      icon: [],
      v_id: 1,
      vegetables_id: "",
    });
    onMounted(() => {
      if (proxy.$route.query.vegetables_id != undefined) {
        data.vegetables_id = proxy.$route.query.vegetables_id;
      }
      console.log(proxy.$route.query.vegetables_id);
      articleVInfo();
    });
    function articleVInfo() {
      proxy.$http
        .get("/EncyclopediaController/selectVegetablesEncyclopedi", {
          vegetables_id: data.vegetables_id,
        })
        .then((res) => {
          console.log(res);
          data.name = res.data[0].name;
          data.features = res.data[0].features;
          data.alias = res.data[0].alias;
          data.section = res.data[0].section;
          data.f_id = res.data[0].f_id;
          let image = res.data[0].picture;
          let url = image;
          // let i = 0;
          console.log(url);
          // for (i; i < url.length; i++) {
          //   let src = url[i].split("'");
          //   data.picuure = data.picuure.concat(src[1]);
          // }
          let a = res.data[0].picture;
          let x = res.data[0].icon;
          let b = "http://localhost:8002/a/";
          img.image = b + a;
          img.imgSrc = b+x;
          console.log(img);
        });
    }
    function str() {}
    function goBack() {
      proxy.$router.go(-1);
    }
    return {
      // imgSrc,
      fits,
      str,
      data,
      goBack,
      img,
    };
  },
};
</script>

<style>
.artV_body_all {
  background-color: white;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.artV_title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
}
.artV_time {
  display: flex;
}
.artV_author {
  display: flex;
  align-items: center;
}
.artV_author_text {
  margin-left: 10px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.artV_info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #f2f2f2;
}
.artV_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.artV_content {
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
  width: 95%;
}
.artV_demo-image {
  display: flex;
  flex-flow: wrap;
}
.actV_block {
  margin: 0 10px -20px 10px;
}
.artV_bottom {
  display: flex;
  justify-content: end;
}
</style>
